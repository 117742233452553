import { render, staticRenderFns } from "./liveinqa.vue?vue&type=template&id=79b93b11&scoped=true"
import script from "./liveinqa.vue?vue&type=script&lang=js"
export * from "./liveinqa.vue?vue&type=script&lang=js"
import style0 from "./liveinqa.vue?vue&type=style&index=0&id=79b93b11&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79b93b11",
  null
  
)

export default component.exports