<template>
    <div>
        <div class="header">
            <span class="header_txt">直播问答</span>
        </div>
        <div class="header_box">
            <div class="left">
                <img src="@/assets/live.png" alt="">
                <span  class="left_span">选择直播：</span>
                <Select v-model="modelSelect" style="width:200px">
                    <Option v-for="item in liveList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </div>
            <div class="right"></div>
        </div>
        <div class="content">
            <Table :columns="liveTable" :data="dataTable" border>
                <template slot-scope="{ row, index }" slot="type">
                    <span class="infoModal_text" @click="answer(index)">回答</span>
                    <span class="infoModal_text" @click="copy">复制链接</span>
                </template>
            </Table>
        </div>
        <div class="footer">
            <Page :total="total" show-elevator show-total @on-change="pageChange"/>
        </div>
        <!-- 回答弹框 -->
        <Modal v-model="answerModal" title="回答问题" width="50%">
            <div>
                <Form ref="answerListForm" :model="answerListForm" :rules="answerListFormRules" :label-width="100">
                    <FormItem label="问题" prop="problem">
                        <Input type="textarea" disabled v-model="answerListForm.problem" style="width: 100%;"></Input>
                    </FormItem>
                    <FormItem label="答案" prop="answer">
                        <Input type="textarea" v-model="answerListForm.answer" style="width: 100%;"></Input>
                    </FormItem>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="answerModalF">取消</Button>
                <Button type="primary" @click="answerModalT('answerListForm')">回答</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                liveList: [
                    {
                        value: '0',
                        label: 'LOL直播盛典'
                    }
                ],
                modelSelect:'0',
                //table
                liveTable: [
                    {title: '问题',key: 'problem',align:'center'},
                    {title: '答案',key: 'answer',align:'center'},
                    {title: '提问时间',key: 'time',align:'center'},
                    {title: '操作',slot: 'type',align:'center'},
                ],
                dataTable: [
                    {
                        problem: '怎么退定金',
                        answer: '',
                        time: '2023-12-22 16:00:00',
                    },
                    {
                        problem: '98做一次的吧!',
                        answer: '',
                        time: '2023-12-22 16:00:00',
                    },
                ],
                //page
                total: 10,
                storePrams: {
                    page: 1,
                    limit: 30,
                },
                answerModal:false,
                answerListForm:{
                    problem:'怎么退定金',
                    answer:'',
                },
                //回答问题弹框-表单验证
                answerListFormRules: {
                    answer: [
                        { required: true, message: '请输入答案', trigger: 'blur' }
                    ],
                },
                textCopy:'Hello World',
            }
        },
        methods: {
            pageChange(index) {
                this.storePrams.page = index;
            },
            //点击'回答'弹出对应的回答问题弹框
            answer(i){
                console.log('打印index:',i)
                this.answerModal = true
            },
            answerModalF(){
                this.answerModal = false
            },
            answerModalT(name){
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        this.$Message.success('保存成功!');
                        this.answerModal = false
                    }
                })      
            },
            copy(){
                this.$copyText(this.textCopy).then(
                    (e) => {
                        this.$Message.success({
                            background: true,
                            content: '复制成功',
                            closable: true
                        });
                    },
                    (err) => {
                    alert("复制失败");
                    console.log(err);
                    }
                );
            },
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.header_box{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
}
.left{
    display: flex;
    align-items: center;
}
.footer{
    margin: 20px 0;
    text-align: center;
}
.left_span{
    height: 21px;
    color: #374a70;
    font-weight: 700;
    font-size: 16px;
    margin-right: 4px;
    margin-left: 2px;
}
::v-deep .ivu-select-selection{
    height: 40px;
}
::v-deep .ivu-select-selected-value{
    height: 40px !important;
    line-height: 40px !important;
}
.infoModal_text{
    color: #409eff;
    margin: 10px;
    font-size: 12px;
    cursor: pointer;
}
</style>